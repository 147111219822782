import { css } from '@emotion/react'
import { ArticleFragment } from 'api/contentful/contentful.graphql.types'
import { ArticleCard, articleCardWidth } from 'components/articleCard/ArticleCard'
import { ElegantArticleCard } from 'components/articleCard/ElegantArticleCard'

export function ArticleCards(props: { articles: ArticleFragment[]; elegant?: boolean }) {
  const articles = props.elegant ? props.articles.slice(0, 3) : props.articles
  return (
    <>
      {articles.map((article) =>
        props.elegant ? (
          <ElegantArticleCard key={article.sys?.id} article={article} />
        ) : (
          <ArticleCard
            key={article.sys?.id}
            title={article.title}
            coverImage={article.coverImage?.url}
            content={article.content}
            href={`/articles/${article.slug}`}
            css={css`
              flex-shrink: 0;
              flex-basis: 75%;
              max-width: ${articleCardWidth}px;
            `}
          />
        )
      )}
    </>
  )
}
