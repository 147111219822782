import { css } from '@emotion/react'
import { ArticleFragment } from 'api/contentful/contentful.graphql.types'
import { ArticlesSearchInput } from 'components/articles/articlesSearchInput/ArticlesSearchInput'
import { ArticleCards } from 'components/articlesSection/cards/ArticleCards'
import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { Spacer } from 'components/spacer/Spacer'
import { HStack, Stack } from 'components/Stack'
import { Caption, Text, Title, Title_h4_default } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import Router from 'next/router'
import { encodeQuery } from 'utils/fetcher'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

export interface IArticlesSectionProps {
  caption: string
  title: string
  description: string
  articles: ArticleFragment[]
}

export function ArticlesSection(props: IArticlesSectionProps) {
  const { t } = useTranslation('estate_planning_lp')

  return (
    <section>
      <Block>
        <Stack gap="1rem">
          <Caption>{props.caption}</Caption>
          <HStack
            gap="2rem"
            css={[
              { justifyContent: 'space-between', alignItems: 'start' },
              BREAKPOINTS.MD.css({
                flexDirection: 'column',
              }),
            ]}
          >
            <Stack gap="1rem">
              <Title variant={Title_h4_default}>{props.title}</Title>
              <Text>{props.description}</Text>
            </Stack>
            <ArticlesSearchInput
              onSearchSubmit={({ search }) => {
                Router.push(encodeQuery('/articles', { search }))
              }}
              foundArticlesCount={props.articles.length}
              css={css`
                min-width: ${pxToRemUnit(200)};
                @media ${BREAKPOINTS.MD.max} {
                  width: 100%;
                }
              `}
            />
          </HStack>
        </Stack>
      </Block>
      <Spacer size={2} />

      <Block>
        <div
          css={[
            { display: 'grid', gap: '3rem', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' },
            BREAKPOINTS.MD.css({
              gridTemplateColumns: 'auto',
              gap: '1rem',
            }),
          ]}
        >
          <ArticleCards articles={props.articles} elegant />
        </div>
      </Block>

      <Spacer size={2} />
      <Block>
        <Button type="button" variant="text" href="/articles">
          <Trans t={t} i18nKey="estate_planning_lp.articles.read_all" />
        </Button>
      </Block>
    </section>
  )
}
