import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'components/icon'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { colors } from 'utils/colors'

interface ArticlesSearchInputForm {
  search: string
}

export function ArticlesSearchInput(props: {
  onSearchSubmit(payload: { search: string }): void | Promise<void>
  query?: string
  foundArticlesCount?: number
  className?: string
}) {
  const { t } = useTranslation()
  const { query: search = '', onSearchSubmit, foundArticlesCount, className } = props
  const noArticlesFound = foundArticlesCount === 0

  const form = useForm<ArticlesSearchInputForm>({ defaultValues: { search } })

  const resetForm = form.reset
  useEffect(() => void resetForm({ search }), [resetForm, search])

  return (
    <form onSubmit={form.handleSubmit(onSearchSubmit)} className={className}>
      <SBox>
        <SSearchIcon>
          <Icon name="Search" color={colors.blue[500]} />
        </SSearchIcon>
        <div
          css={css`
            position: relative;
            width: 100%;
            display: flex;
          `}
        >
          <SInput
            {...form.register('search')}
            type="text"
            placeholder={t('common.search_articles')}
          />
          {noArticlesFound && search && (
            <Icon
              name="Warning"
              css={css`
                position: absolute;
                top: 0;
                right: 0.25rem;
              `}
              size="1.25rem"
              color={colors.red[500]}
            />
          )}
        </div>
      </SBox>
    </form>
  )
}

const SBox = styled.div`
  border: 1px solid ${colors.blue[100]};
  border-radius: 2px;
  display: flex;
  align-items: stretch;
  height: 60px;
  margin-bottom: 28px;
  background: #fff;
  overflow: hidden;
  padding: 0rem 1rem;
`

const SInput = styled.input`
  border: none;
  outline: none;
  &::-webkit-input-placeholder {
    color: ${colors.gray[100]};
  }
  &::-moz-placeholder {
    color: ${colors.gray[100]};
  }

  font-family: var(--f-text);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  flex-grow: 1;
`

export const SSearchIcon = styled.div`
  display: flex;
  flex-shrink: 0;

  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  outline: none;
  padding-right: 0.75rem;
`
