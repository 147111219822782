import { ArticleFragment } from 'api/contentful/contentful.graphql.types'
import { AspectRatio } from 'components/AspectRatio'
import { PrimaryLink } from 'components/Link'
import { Spacer } from 'components/spacer/Spacer'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { colors } from 'utils/colors'
import { encodeQuery } from 'utils/fetcher'
import {
  SArticleCardButton,
  SArticleCardContainer,
  SArticleCardContentContainer,
  SArticleCardImage,
} from './ArticleCard.styled'

export const ElegantArticleCard: FC<{ article: ArticleFragment }> = ({ article }) => {
  const { t } = useTranslation()
  return (
    <SArticleCardContainer css={{ boxShadow: 'var(--card-shadow)', border: 0 }}>
      <AspectRatio ratio={2}>
        {article.coverImage?.url && (
          <SArticleCardImage
            src={encodeQuery(article.coverImage.url, {
              w: 500,
              h: 200,
              fit: 'thumb',
            })}
            loading="lazy"
            alt={article.title ?? undefined}
          />
        )}
      </AspectRatio>
      <SArticleCardContentContainer>
        <Text variant="bodyLargeBold" color={colors.gray[100]}>
          {article.title}
        </Text>

        <Spacer size={0.75} />

        <SArticleCardButton css={{ justifyContent: 'start' }}>
          <PrimaryLink href={`/articles/${article.slug}`} css={{ color: colors.gray[500] }}>
            {t('common.article_card.read_button')}
          </PrimaryLink>
        </SArticleCardButton>
      </SArticleCardContentContainer>
    </SArticleCardContainer>
  )
}
