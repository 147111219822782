import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { colors } from 'utils/colors'

export const SArticleCardContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.blue[100]};
`

export const SArticleCardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  object-fit: cover;
  object-position: center;

  width: 100%;
  height: 100%;
`

export const SArticleCardContentContainer = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SArticleCardContentDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const SArticleCardButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  flex: 1;
  margin-right: 0.75rem;
`
