import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { IContentfulRichText } from 'api/contentful/contentful.types'
import { ReactElement, ReactNode } from 'react'

export function ContentfulFirstParagraphText(props: {
  content: IContentfulRichText
  wrapper?: ReactElement
}) {
  const raw = props.content?.json
  const firstParagraph = raw.content?.find((node) => node.nodeType === 'paragraph')

  if (!firstParagraph) {
    return <></>
  }

  const documentWithFirstParagraph: Document = {
    nodeType: BLOCKS.DOCUMENT,
    content: [firstParagraph],
    data: {},
  }

  const components = documentToReactComponents(documentWithFirstParagraph)

  const res = components && getRecursiveChildText(components)
  return <>{res}</>
}

const getRecursiveChildText = (
  reactNode: ReactNode | { props: { children: ReactElement } }
): string => {
  let children
  if (
    !reactNode ||
    typeof reactNode === 'string' ||
    typeof reactNode === 'number' ||
    typeof reactNode === 'boolean'
  ) {
    children = undefined
  } else {
    if ('props' in reactNode) {
      children = reactNode.props.children
    }
  }

  if (Array.isArray(reactNode)) {
    // Multiple children
    const joinedNodes: string[] = []
    reactNode.forEach((node) => {
      if (typeof node === 'object') joinedNodes.push(getRecursiveChildText(node))
      else if (typeof node === 'string') joinedNodes.push(node)
    })
    return joinedNodes.join('')
  }
  if (children === undefined) {
    if (typeof reactNode === 'string') return reactNode
    else return ''
  }
  if (typeof children === 'object') {
    // Found direct child
    return getRecursiveChildText(children) as string
  }
  if (typeof children === 'string') {
    // Found searchable string
    return children
  }

  return ''
}
