import { IContentfulRichText } from 'api/contentful/contentful.types'
import { AspectRatio } from 'components/AspectRatio'
import { ContentfulFirstParagraphText } from 'components/content/contentfulContent/contentfulFirstParagraph/ContentfulFirstParagraph'
import { PrimaryLink } from 'components/Link'
import { Spacer } from 'components/spacer/Spacer'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { forwardRef } from 'react'
import { colors } from 'utils/colors'
import { encodeQuery } from 'utils/fetcher'
import {
  SArticleCardButton,
  SArticleCardContainer,
  SArticleCardContentContainer,
  SArticleCardContentDescription,
  SArticleCardImage,
} from './ArticleCard.styled'

export const articleCardWidth = 286

export const ArticleCard = forwardRef<
  HTMLDivElement,
  {
    title?: string | null
    coverImage?: string | null
    className?: string
    onClick?: () => void
    href?: string
    content?: IContentfulRichText | null
  }
>(({ title, content, coverImage, onClick, className, href }, ref) => {
  const { t } = useTranslation()

  return (
    <SArticleCardContainer onClick={onClick} ref={ref} className={className}>
      <AspectRatio ratio={articleCardWidth / 200}>
        {coverImage && (
          <SArticleCardImage
            src={encodeQuery(coverImage, {
              w: articleCardWidth,
              h: 200,
              fit: 'thumb',
            })}
            loading="lazy"
            alt={title ?? undefined}
          />
        )}
      </AspectRatio>
      <SArticleCardContentContainer>
        <Text variant="bodyLargeBold" color={colors.gray[100]}>
          {title}
        </Text>
        <Spacer size={0.75} />

        {content && (
          <SArticleCardContentDescription variant="bodyLarge" color={colors.gray[500]}>
            <ContentfulFirstParagraphText content={content} />
          </SArticleCardContentDescription>
        )}

        <Spacer size={0.75} />
        <SArticleCardButton>
          <PrimaryLink href={href}>{t('common.read_more')}</PrimaryLink>
        </SArticleCardButton>
      </SArticleCardContentContainer>
    </SArticleCardContainer>
  )
})

ArticleCard.displayName = 'ArticleCard'
